import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-to"
export default class extends Controller {
  initialize () {
    this.scroll = this.scroll.bind(this)
  }

  connect () {
    this.element.addEventListener('click', this.scroll)
  }

  disconnect () {
    this.element.removeEventListener('click', this.scroll)
  }

  scroll (event) {
    event.preventDefault()

    const id = this.element.getAttribute('href').replace(/^#/, '')
    const target = document.getElementById(id);
    const offsetPosition = target.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: offsetPosition
    })
  }
}
